import {
  ProductDto,
  ProductQuery,
  SpecialtyTyreDto,
  stores,
} from "@oaktyres/model";
import {
  useAccountNew,
  useManufacturers,
  useProductCategories,
  useProducts,
  useSpecialtyTyres,
} from "@oaktyres/queries";
import {
  Box,
  Breadcrumb,
  Breadcrumbs,
  Circle,
  Flex,
  Grid,
  InputWithSearch,
  Loader,
  Panel,
  ProductCard,
  ProductModal,
  Text,
  TyreModal,
  useAuth,
} from "@oaktyres/ui";
import { max, sortBy } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaQuestion } from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router";
import { useDebounce } from "react-use";
import styled from "styled-components";
import { useScopedBasket } from "../../basket";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import opSrc from "../../img/fullpartnership.png";
import { ProductCategoryDisplay } from "../Store/ProductCategoryList";
import { SpecialtyTyreCard } from "./SpecialtyTyreCard";

const BrandIcon = styled.img`
  border-radius: 6px;
  height: 40px;
`;

const NoCategories = () => (
  <Flex
    alignItems="center"
    justifyContent={"center"}
    p={5}
    flexDirection="column"
  >
    <Circle color="grey3" icon={FaQuestion} size="xlarge" mb={2} />
    <Text color="grey3" fontWeight={600} fontSize={4}>
      No Categories To Show
    </Text>
  </Flex>
);

export const SpecialtyTyreStorePage = () => {
  const history = useHistory();
  const { categoryId } = useParams<{
    categoryId?: string;
  }>();

  const [shownProduct, setShownProduct] = useState<string | null>(null);

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [accountCode] = useScopedAccount();
  const account = useAccountNew(accountCode);
  const categories = useProductCategories();
  const activeCategory = (categories.data ?? []).find(
    (x) => x.id === categoryId,
  );
  const basket = useScopedBasket();
  const hasSearch =
    debouncedSearch.trim().length > 0 && search.trim().length > 0;

  const tyres = useSpecialtyTyres({ accountCode });

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    200,
    [search],
  );

  const showProduct = (product: SpecialtyTyreDto) => {
    setShownProduct(product.stockCode);
  };

  const shownCategories = sortBy(
    (categories.data ?? []).filter((x) => x.store === "specialty"),
    (x) => x.name,
  ).filter((x) => x.isActive);

  const hasActiveQuery = categoryId != null || hasSearch;

  let breadCrumbs: Breadcrumb[] = [
    {
      url: `/store/specialty`,
      label: "Specialty Tyres",
    },
  ];

  if (activeCategory) {
    breadCrumbs.push({
      url: `/store/specialty/${activeCategory.id}`,
      label: activeCategory.name,
    });
  }

  if (hasSearch) {
    breadCrumbs = [{ url: null, label: "Search Results" }];
  }

  return (
    <Box width="100%">
      {shownProduct && (
        <TyreModal
          accountCode={accountCode}
          onClose={() => setShownProduct(null)}
          stockCode={shownProduct}
          basketItems={basket.items}
          onAddToBasket={(qty, supply) =>
            basket.addToBasket(shownProduct, qty, supply)
          }
        />
      )}
      <Panel p={2} pl={[2, 2, 4]} mb={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={["stretch", "stretch", "center"]}
          flexDirection={["column", "column", "row"]}
        >
          <Box flex={1} mb={[1, 1, 0]}>
            <Breadcrumbs items={breadCrumbs} />
          </Box>
          <InputWithSearch
            maxWidth={"100%"}
            searching={tyres.isLoading || search !== debouncedSearch}
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            onClear={() => {
              setSearch("");
              setDebouncedSearch("");
            }}
          />
        </Flex>
      </Panel>
      {hasActiveQuery ? (
        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
          gridGap={2}
          key="products"
        >
          {(tyres.data ?? []).map((x) => (
            <SpecialtyTyreCard
              product={x}
              onClick={() => showProduct(x)}
              key={x.id}
            />
          ))}
        </Grid>
      ) : account.isLoading || categories.isLoading ? (
        <Loader />
      ) : shownCategories.length === 0 ? (
        <NoCategories />
      ) : (
        <>
          <Grid
            gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
            gridGap={2}
            key="categories"
            mb={5}
          >
            {shownCategories.map((x) => (
              <ProductCategoryDisplay
                key={x.id}
                item={x}
                onClick={() => history.push(`/store/specialty/${x.id}`)}
              />
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};
