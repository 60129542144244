import { ReactComponent as ConsumablesIcon } from "../img/iconconsumables.svg";
import { ReactComponent as TyreIcon } from "../img/icontyre.svg";
import { ReactComponent as ToolsIcon } from "../img/icontools.svg";
import { ReactComponent as InnerTubesIcons } from "../img/iconinnertubes.svg";
import { ReactComponent as TpmsIcon } from "../img/icontpms.svg";
import { ReactComponent as DspIcon } from "../img/icondsp.svg";
import { ReactComponent as RewardsIcon } from "../img/iconrewards.svg";
import { ReactComponent as BdfIcon } from "../img/iconbusdev.svg";
import { ReactComponent as BasketIcon } from "../img/iconbasket.svg";
import { ReactComponent as OrderHistoryIcon } from "../img/orderhistory.svg";
import { ReactComponent as NextVanIcon } from "../img/iconnextvan.svg";
import { ReactComponent as RemitPaymentIcon } from "../img/remitpayment.svg";
import { ReactComponent as PaymentHistoryIcon } from "../img/paymenthistory.svg";
import React from "react";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";
import styled from "styled-components";

type IconProps = ColorProps & SpaceProps & LayoutProps;

const makeIcon = (comp: React.FC<React.SVGProps<SVGSVGElement>>) => styled(
  comp,
)<IconProps>`
  ${color};
  ${space};
  ${layout};
`;

const IconComponent = styled.svg<IconProps>``;

const icons = {
  consumables: makeIcon(ConsumablesIcon),
  tyre: makeIcon(TyreIcon),
  tne: makeIcon(ToolsIcon),
  innertubes: makeIcon(InnerTubesIcons),
  tpms: makeIcon(TpmsIcon),
  dealersupport: makeIcon(DspIcon),
  rewards: makeIcon(RewardsIcon),
  dsp: makeIcon(DspIcon),
  rp: makeIcon(RewardsIcon),
  cc: makeIcon(ConsumablesIcon),
  bdf: makeIcon(BdfIcon),
  basket: makeIcon(BasketIcon),
  gbp: makeIcon(() => null),
  orderhistory: makeIcon(OrderHistoryIcon),
  nextvan: makeIcon(NextVanIcon),
  remitpayment: makeIcon(RemitPaymentIcon),
  paymenthistory: makeIcon(PaymentHistoryIcon),
  specialty: makeIcon(TyreIcon),
} as const;

export type PortalIconType = keyof typeof icons;

export type PortalIconProps = React.ComponentProps<typeof IconComponent> & {
  iconType: PortalIconType;
};

export const PortalIcon = ({ iconType, ...rest }: PortalIconProps) => {
  const Icon = icons[iconType];

  return <Icon {...rest} />;
};
