import { ProductDto, ProductQuery, stores } from "@oaktyres/model";
import {
  useAccountNew,
  useManufacturers,
  useProductCategories,
  useProducts,
} from "@oaktyres/queries";
import {
  Alert,
  Box,
  Breadcrumb,
  Breadcrumbs,
  Circle,
  Flex,
  Grid,
  InputWithSearch,
  Loader,
  Panel,
  ProductCard,
  ProductModal,
  Text,
  useAuth,
} from "@oaktyres/ui";
import { max, sortBy } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaInfoCircle, FaQuestion } from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router";
import { useDebounce } from "react-use";
import styled from "styled-components";
import { useScopedBasket } from "../../basket";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { ProductCategoryDisplay } from "./ProductCategoryList";
import opSrc from "../../img/fullpartnership.png";

const BrandIcon = styled.img`
  border-radius: 6px;
  height: 40px;
`;

const NoCategories = () => (
  <Flex
    alignItems="center"
    justifyContent={"center"}
    p={5}
    flexDirection="column"
  >
    <Circle color="grey3" icon={FaQuestion} size="xlarge" mb={2} />
    <Text color="grey3" fontWeight={600} fontSize={4}>
      No Categories To Show
    </Text>
  </Flex>
);

export const StorePage = () => {
  const history = useHistory();
  const { storeId, categoryId } = useParams<{
    storeId: string;
    categoryId?: string;
  }>();

  const auth = useAuth();
  const brand = new URLSearchParams(useLocation().search).get("brand");

  const [shownProduct, setShownProduct] = useState<
    [string, string | null] | null
  >(null);

  const manus = useManufacturers();
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [accountCode] = useScopedAccount();
  const account = useAccountNew(accountCode);
  const activeStore = stores.find((x) => x.id === storeId);
  const categories = useProductCategories();
  const activeCategory = (categories.data ?? []).find(
    (x) => x.id === categoryId,
  );
  const basket = useScopedBasket();
  const hasSearch =
    debouncedSearch.trim().length > 0 && search.trim().length > 0;
  const products = useProducts({
    category: hasSearch ? undefined : categoryId,
    search: hasSearch ? debouncedSearch : undefined,
    brand: brand,
    accountCode: accountCode,
  });

  let brands =
    storeId === "dealersupport"
      ? (manus.data?.filter((x) => x.dspCustintFlag != null) ?? [])
      : [];

  brands = brands.filter((x) =>
    account.data?.brandDealers.some((y) => y.brand === x.crossReference),
  );

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    200,
    [search],
  );

  useEffect(() => {
    setSearch("");
    setDebouncedSearch("");
  }, [storeId]);

  if (activeStore == null) {
  }

  const showProduct = (product: ProductDto) => {
    setShownProduct([product.id, product.variantId ?? null]);
  };

  const shownCategories = sortBy(
    (categories.data ?? []).filter((x) => x.store === storeId),
    (x) => x.name,
  ).filter((x) => x.isActive);

  const hasActiveQuery = categoryId != null || hasSearch;

  let breadCrumbs: Breadcrumb[] = [
    {
      url: `/store/${storeId}`,
      label: activeStore?.name ?? "",
    },
  ];

  if (storeId === "dealersupport") {
    breadCrumbs.unshift({
      url: `/partnership`,
      label: "",
      img: opSrc,
    });
  }

  if (activeCategory) {
    breadCrumbs.push({
      url: `/store/${storeId}/${activeCategory.id}`,
      label: activeCategory.name,
    });
  }

  if (hasSearch) {
    breadCrumbs = [{ url: null, label: "Search Results" }];
  }

  const allowedProducts = (products.data ?? []).filter(
    (x) =>
      x.dealerSupportBrand == null ||
      (auth.user?.type === "user" &&
        account.data?.brandDealers
          .map((x) => x.brand)
          .includes(x.dealerSupportBrand)),
  );

  const isDsp = activeStore?.id === "dealersupport";
  const isDecember = new Date().getMonth() === 11;

  return (
    <Box width="100%">
      {shownProduct && (
        <ProductModal
          accountCode={accountCode}
          onClose={() => setShownProduct(null)}
          productId={shownProduct[0]}
          variantId={shownProduct[1]}
          basketItems={basket.items}
          onAddToBasket={(code, qty) =>
            basket.addToBasket(code, qty, "delivery")
          }
        />
      )}
      <Panel p={2} pl={[2, 2, 4]} mb={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={["stretch", "stretch", "center"]}
          flexDirection={["column", "column", "row"]}
        >
          <Box flex={1} mb={[1, 1, 0]}>
            <Breadcrumbs items={breadCrumbs} />
          </Box>
          <InputWithSearch
            maxWidth={"100%"}
            searching={products.isLoading || search !== debouncedSearch}
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            onClear={() => {
              setSearch("");
              setDebouncedSearch("");
            }}
          />
        </Flex>
      </Panel>
      {isDsp && isDecember && (
        <Alert color="info" icon={FaInfoCircle} mb={2}>
          <Text fontWeight={600}>Delivery Information</Text>
          <Text fontSize={1}>
            Orders placed after December 6th will be dispatched after January
            2nd 2025. We apologise for any inconvenience this may cause.
          </Text>
        </Alert>
      )}
      {hasActiveQuery ? (
        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
          gridGap={2}
          key="products"
        >
          {sortBy(allowedProducts, (x) =>
            activeStore?.id === "innertubes"
              ? 1000 - parseFloat(x.name)
              : max(x.variants.map((x) => x.popularity)),
          )
            .reverse()
            .filter((x) => x.variants.some((x) => x.published))
            .map((x) => (
              <ProductCard
                product={x}
                onClick={() => showProduct(x)}
                key={x.variantId ?? x.id}
              />
            ))}
        </Grid>
      ) : account.isLoading || categories.isLoading ? (
        <Loader />
      ) : shownCategories.filter((x) => x.brands.includes("none")).length +
          brands.length ===
        0 ? (
        <NoCategories />
      ) : (
        <>
          <Grid
            gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
            gridGap={2}
            key="categories"
            mb={5}
          >
            {shownCategories
              .filter((x) => x.brands.includes("none"))
              .map((x) => (
                <ProductCategoryDisplay
                  key={x.id}
                  item={x}
                  onClick={() => history.push(`/store/${storeId}/${x.id}`)}
                />
              ))}
          </Grid>
          {brands.map((b) => (
            <>
              <Flex borderBottom={2} alignItems="flex-end" mb={2} pb={2}>
                <BrandIcon
                  src={`${process.env.REACT_APP_STATIC_ROOT}${b.icon}`}
                />
                <Text ml={2} fontWeight={600} fontSize={3} lineHeight={1}>
                  {b.name}
                </Text>
              </Flex>
              <Grid
                gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))"
                gridGap={2}
                key="categories"
                mb={5}
              >
                {shownCategories
                  .filter((c) => c.brands.includes(b.crossReference))
                  .map((x) => (
                    <ProductCategoryDisplay
                      key={x.id}
                      item={x}
                      brand={b.crossReference}
                      onClick={() =>
                        history.push(
                          `/store/${storeId}/${x.id}?brand=${b.crossReference}`,
                        )
                      }
                    />
                  ))}
              </Grid>
            </>
          ))}
        </>
      )}
    </Box>
  );
};
