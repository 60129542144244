import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Flex from "./Flex";
import Modal from "./Modal";

type SelectorModalProps<T> = {
  items: T[];
  render: (item: T) => React.ReactNode;
  onSelect: (item: T) => void;
  onClose: () => void;
};

const ItemContainer = styled.button`
  margin-bottom: 3px;
  text-align: left;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 3px;
  padding: 3px 6px;
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }
`;

export function SelectorModal<T>({
  items,
  render,
  onSelect,
  onClose,
}: SelectorModalProps<T>) {
  return (
    <Modal onClose={onClose} shown maxHeight={600}>
      <Flex mb={2} flexDirection="column">
        {items.map((x) => (
          <ItemContainer onClick={() => onSelect(x)}>{render(x)}</ItemContainer>
        ))}
      </Flex>
      <Button onClick={() => onClose()}>Close</Button>
    </Modal>
  );
}
