import { useAccountNew, useDeliveryInfo } from "@oaktyres/queries";
import { Box, Flex, Grid, Label, Loader, Panel, Text } from "@oaktyres/ui";
import { range } from "lodash";
import React from "react";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

const LabeledInfo = ({ label, value }: { label: string; value: string }) => (
  <Box>
    <Text fontWeight={600} color="grey2">
      {label}
    </Text>
    <Text>{value}</Text>
  </Box>
);

export const AccountDeliveryPane = () => {
  const [scopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);
  const deliveryInfo = useDeliveryInfo(scopedAccount);

  if (account.data == null || deliveryInfo.data == null) {
    return (
      <Panel width="100%" p={5}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Panel width="100%" p={3}>
      <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
        <Text fontWeight={600} mb={2}>
          Delivery Information
        </Text>
        <Grid gridGap={1} gridTemplateColumns={["1fr", "1fr 1fr"]}>
          <LabeledInfo label="Account Code" value={account.data.code} />
          <LabeledInfo
            label="Depot Name"
            value={deliveryInfo.data.defaultDepot.name}
          />
          {deliveryInfo.data.runs.length > 0 && (
            <div>
              <Text fontWeight={600} color="grey2" mb={1}>
                Cutoff Times
              </Text>
              {range(1, 7).map((x) => (
                <Flex alignItems={"center"} mb={1}>
                  <Text
                    fontWeight={600}
                    width="60px"
                    color="grey1"
                    fontSize={1}
                  >
                    {dayNames[x]}:
                  </Text>
                  <Flex>
                    {deliveryInfo
                      .data!.runs.filter((r) => r.day === x)
                      .sort((a, b) => a.cutoff.localeCompare(b.cutoff))
                      .map((x) => (
                        <Label fontFamily={"mono"} labelSize="small">
                          {x.cutoff}
                        </Label>
                      ))}
                  </Flex>
                </Flex>
              ))}
            </div>
          )}
        </Grid>
      </Flex>
      {deliveryInfo.data.collectionDepots.filter((x) => x.address != null)
        .length === 0 && (
        <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
          <Text fontWeight={600} mb={2}>
            Collection Depots
          </Text>
          <Text>
            Your account currently has no collection depots enabled. If you
            would like to be able to place orders from collection from one of
            our depots, please contact your Business Development Manager
          </Text>
        </Flex>
      )}
      {deliveryInfo.data.collectionDepots
        .filter((x) => x.address != null)
        .map(({ address, name }, i) => (
          <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
            <Text fontWeight={600} mb={2}>
              Collection Address {i + 1} ({name})
            </Text>
            <Grid gridGap={1} gridTemplateColumns={["1fr", "1fr 1fr"]}>
              <LabeledInfo label="Address 1" value={address!.address1} />
              <LabeledInfo label="Address 2" value={address!.address2} />
              <LabeledInfo label="City" value={address!.city} />
              <LabeledInfo label="County" value={address!.county} />
              <LabeledInfo label="Post Code" value={address!.postcode} />
            </Grid>
          </Flex>
        ))}
    </Panel>
  );
};
