// API

import { PriceDto, TyreAvailability } from "./api/TyreSearchPayload";
import { ReturnReasonCode } from "./business/returns";
import { SpecialtyTreadStyleType } from "./business/specialtytyres";
import { StoreId } from "./business/stores";
import { CamStatuses, StopReasons } from "./db/CameoCustomer";
import { ProspectStatus } from "./db/DbAccount";
import { PersonTitle } from "./db/DbCallReportAttendee";

export * from "./business/units";
export * from "./business/names";
export * from "./business/time";
export * from "./business/depots";

export { AccountSpec } from "./api/AccountSpec";
export * from "./api/ApplicationSpec";
export * from "./api/ExclusionPayload";
export {
  GetAccountPayload,
  GetAccountPayloadAdmin,
  AccountDto,
} from "./api/GetAccountPayload";
export * from "./api/GetContactsPayload";
export * from "./api/ManufacturerPayload";
export * from "./api/NonStockPayload";
export { OrderHistoryPayload } from "./api/OrderHistoryPayload";
export { ProspectSpec } from "./api/ProspectSpec";
export * from "./api/Rebates";
export { StockPayload } from "./api/StockPayload";
export * from "./api/TyreSearchOptions";
export * from "./api/TyreSearchPayload";
export { CameoCustomer, CamStatuses, StopReasons } from "./db/CameoCustomer";
// DB
export { DbAccount, DealerSupportBrand, ProspectStatus } from "./db/DbAccount";
export { DbAdministrator } from "./db/DbAdministrator";
export { DbCallReportAttendee, PersonTitle } from "./db/DbCallReportAttendee";
export { DbUserGroupMembership } from "./db/DbUserGroupMembership";
export * from "./business/returns";
export * from "./business/fitments";
export * from "./business/specialtytyres";

export type DataSheetType = "safety" | "technical" | "instructions";

export const DataSheetTypeNames: Record<DataSheetType, string> = {
  safety: "Safety Data Sheet",
  technical: "Technical Data Sheet",
  instructions: "Instructions",
};

export type DataSheetSpec = {
  fileId: string;
  associatedStockCodes: string[];
  type: DataSheetType;
};

export type DataSheetDto = {
  id: string;
  stockCodes: string[];
  originalFilename: string;
  type: DataSheetType;
};

export type DataSheetForProductDto = {
  type: DataSheetType;
  productName: string;
  stockCode: string;
  id: string;
};
export type SignedUploadResponse = {
  fileId: string;
  uploadUrl: string;
  key: string;
};

export type AdhocRewardLedgerAdjustment = {
  account: string;
  type: CurrencyType;
  earn: number;
  spend: number;
  description?: string;
};

export type ContactSpec = {
  id?: number;
  isDepartment?: boolean | null;
  title: PersonTitle;
  name: string;
  surname?: string | null;
  email?: string | null;
  directPhone?: string | null;
  mobileNumber?: string | null;
  faxNumber?: string | null;
  jobTitle?: string | null;
  isBusinessOwner?: boolean | null;
  makesPurchasingDecisions?: boolean | null;
  daytimeAlertsEmail: boolean;
  daytimeAlertsSms: boolean;
  nightAlertsEmail: boolean;
  nightAlertsSms: boolean;
  accountID?: number;
  prospectID?: number;
};

export type AccountUserDto = {
  id: string;
  email: string;
  name: string;
  hasMigrated: boolean;
  ownedAccounts: {
    name: string;
    code: string;
    ledgeredAccounts: string[];
    dspBrands: string[];
  }[];
};

export type PhysicalAddressDto = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  postcode: string;
};

export type VehicleInfoDto = {
  odometerReading: { value: number; isEstimate: boolean } | null;
  registration: string;
  make: string;
  model: string;
  year: number;
};

export type FaultyNonTyreReturnSpec = {
  type: "faultynontyre";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  complaint: string;
};

export type NewGoodReturnSpec = {
  type: "newgood";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  complaint: string;
};

export type FaultyTyreReturnSpec = {
  type: "faultytyre";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  consumerName: string;
  consumerEmail: string;
  complaint: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  postcode: string;
  supportingImages: {
    originalFilename?: string;
    filename: string;
  }[];
  lines: {
    dot: string;
    barcode: string;
    wheelPosition: WheelPosition | "";
    mileage: number | null;
    isMileageEstimated: boolean;
    purchaseDate: number;
    fittingDate: number | null;
    returnCode: ReturnReasonCode | "";
    returnInfo: string;
    treadDepth1: number;
    treadDepth2: number;
    treadDepth3: number;
    treadDepth4: number | null;
  }[];
  vehicleRegistration: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleOdometerReading: string;
};

export type ReturnRequestSpec =
  | FaultyNonTyreReturnSpec
  | NewGoodReturnSpec
  | FaultyTyreReturnSpec;

export type IdentityType = "staff" | "account" | "user" | "apikey";

export type IdentityDisplay = {
  id: string;
  name: string;
  type: IdentityType;
  email?: string;
  profileImage?: string;
  phoneNumber?: string | null;
};

export interface IIdentityDisplayProvider {
  asIdentityDisplay(): IdentityDisplay;
}

export type ApiKeyDto = {
  id: string;
  name: string;
  createdBy: IdentityDisplay;
  createdOn: number;
  useCount: number;
  lastUsed: number | null;
  key?: string;
};

export type FaultyNonTyreReturnDto = {
  id: string;
  type: "faultynontyre";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  complaint: string;
  reference: string;
  creator: IdentityDisplay;
  creationDate: number;
};

export type NewGoodReturnDto = {
  id: string;
  type: "newgood";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  reason: string;
  reference: string;
  creator: IdentityDisplay;
  creationDate: number;
};

export type FaultyTyreReturnDto = {
  id: string;
  type: "faultytyre";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  consumerName: string;
  reference: string;
  creator: IdentityDisplay;
  creationDate: number;
  consumerAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
  };
  complaint: string;
  supportingImages: {
    originalFilename?: string;
    filename: string;
  }[];
  lines: {
    id: string;
    dot: string;
    barcode: string;
    wheelPosition: WheelPosition;
    mileage: {
      value: number;
      isEstimated: boolean;
    };
    purchaseDate: number;
  }[];
  vehicle: {
    registration: string;
    make: string;
    model: string;
    year: number;
    odometerReading: number;
  };
};

export type ReturnRequestDto =
  | FaultyNonTyreReturnDto
  | NewGoodReturnDto
  | FaultyTyreReturnDto;

export type JobLogItemSummaryDto = {
  id: string;
  jobName: string;
  jobId: string;
  startedOn: number;
  completion: number;
  finishedOn: number | null;
  isDryRun: boolean;
  status: "failed" | "completed" | "running";
};

export type JobLogItemDto = JobLogItemSummaryDto & {
  output: string | null;
};
export * from "./business/stores";

export const wheelPositions = ["fr", "fl", "rr", "rl"] as const;

export type WheelPosition = (typeof wheelPositions)[number];

export const isWheelPosition = (input: any): input is WheelPosition =>
  typeof input === "string" && wheelPositions.includes(input as WheelPosition);

export const wheelPositionNames: Record<WheelPosition, string> = {
  fr: "Front Right",
  fl: "Front Left",
  rl: "Rear Left",
  rr: "Rear Right",
};

export type FileUploadPermission = {
  url: string;
  key: string;
};

export type ReturnRequestType =
  | "newgood"
  | "faultytyre"
  | "dealerwarranty"
  | "faultynontyre";

export const payTypeNames = {
  AC: "Cash",
  AP: "Automated Payment",
  AQ: "Cheque",
  BD: "Bad Debt",
  CA: "Cash",
  CC: "Credit Card",
  CQ: "Cheque",
  CR: "Credit",
  DD: "Direct Debit",
  ER: "Euro Direct Credit",
  EU: "Euro Cheque",
  IN: "Invoice",
  PP: "Paypal Receipt",
  RB: "Customer Rebate",
  SP: "Sagepay Receipt",
  US: "USD Payment",
} as const;

export type PayTypeCode = keyof typeof payTypeNames;

export type PaymentDto = {
  camId: string;
  accountCode: string;
  payType: PayTypeCode;
  date: number;
  reference: string;
  value: number;
};

export type RemittanceSpec = {
  accountCode: string;
  paymentMethod: string;
  items: string[];
};

export type AccountFinancialsDto = {
  code: string;
  creditLimit: number;
  insuredLimit: number;
  balanceCurrent: number;
  balance30Day: number;
  balance60Day: number;
  balance90Day: number;
  balance120Day: number;
  balanceTotal: number;
  availableBalance: number;
  extraPay: number;
  payTerms: number;
  payType: PayTypeCode;
  payDay: number;
  tradingTerms: number[];
};

export type RemittableItemDto = {
  camId: string;
  accountCode: string;
  date: number;
  transactionType: string;
  reference: string;
  adviceNoteNumber: string;
  orderNumber: string;
  dueDate: number;
  totalValue: number;
  amountDue: number;
  monthIndex: number;
  creditedReference: string | null;
  associatedRemittance: string | null;
  hasUnpaidInvoice: boolean;
};

export type RemittanceItemDto = {
  id: string;
  camId: string;
  allocatedValue: number;
  unallocatedValue: number;
  referenceNumber: string;
  dueDate: number;
};

export type PaymentMethodIdent = "cheque" | "card" | "banktransfer";

export type RemittanceDto = {
  id: string;
  accountCode: string;
  createdOn: number;
  markedAsPaid: number | null;
  paymentMethod: PaymentMethodIdent;
  reference: string;
  items: RemittanceItemDto[];
};

export type ClosedPeriodDto = {
  id: string;
  start: number;
  end: number;
  comment: string;
};

export type VanRunDto = {
  id: string;
  day: number;
  hours: number;
  minutes: number;
  depot: number;
};

export type OrderLineStatus =
  | "pending"
  | "delivered"
  | "credited"
  | "outfordelivery"
  | "awaitingdispatch"
  | "backorder"
  | "dispatchedbycourier"
  | "complete"
  | "readyforcollection"
  | "resolved"
  | "cancelled"
  | "dataunavailable";

export type OrderHistoryDto = {
  portalId: string | null;
  adviceNoteNumber: string | null;
  orderReference: string | null;
  reg: string | null;
  date: number;
  fitter: string;
  resolves: string | null;
  lines: {
    isBackOrder: boolean;
    stockCode: string;
    scheduledDispatch: number | null;
    description: string;
    manufacturer: string;
    qty: number;
    price: number;
    currency: CurrencyType;
    status: OrderLineStatus;
    creditNote: string | null;
    documents: string[];
    vanrun: string | null;
    resolution: InvoiceLineResolutionDto | null;
    returns: {
      id: string;
      ref: string;
      quantity: number;
    }[];
  }[];
};

export type StockHistoryEntryDto = {
  date: number;
  accountCode: string;
  invoice: string;
  adviceNote: string;
  customerOrderRef: string;
  quantity: number;
  price: number;
};

export type AuthResponse = {
  id: string;
  type: IdentityType;
  displayName: string;
  ownedAccounts: { name: string; code: string }[];
};

export type AuthRequest = {
  ident: string;
  password: string;
  type: "staff" | "account" | "user";
};

export type EnrollmentStatusDto = {
  accountCode: string;
  enrollmentStatus: "not-enrolled" | "enrolled";
};

export type EnrollmentChallengeOptionDto = {
  patternName: string;
  stockCode: string;
  manufacturer: string;
  qty: number;
  price: number;
};

export type EnrollmentChallengeDto = {
  id: string;
  accountCode: string;
  invoiceNumber: string;
  options: EnrollmentChallengeOptionDto[];
  failed: boolean;
  respondedOn: number | null;
};

export type EnrollmentChallengeResponseDto = {
  failed: boolean;
  resetCode: string | null;
};

export type NoStockBehaviourType = "hide" | "nosale" | "sale";

export type AttributeValueSpec = {
  id: string;
  value: string | number | [number | string, number | string] | string[] | null;
  tolerance?: number;
  packName?: string;
};

export type ProductQuery = {
  accountCode?: string | null;
  category?: string | null;
  search?: string | null;
  brand?: string | null;
};

export type ProductVariantSpec = {
  id?: string;
  stockCode: string;
  name: string;
  images: string[];
  published: boolean;
  showInSearches: boolean;
  noStockBehaviour: NoStockBehaviourType;
  leadTime: string | null;
  attributes: AttributeValueSpec[];
};

export type ProductSpec = {
  id?: string;
  name: string;
  description: string;
  images: string[];
  attributes: AttributeValueSpec[];
  keywords: string | string[];
  productType: string;
  variants: ProductVariantSpec[];
  dealerSupportBrand: string;
};

export type ProductAuditRow = {
  stockCode: string;
  productGroup: string;
  description: string;
  stockBalance: number;
};
export type ProductVariantDto = {
  id: string;
  stockCode: string;
  manufacturer: string;
  name: string;
  images: string[];
  published: boolean;
  showInSearches: boolean;
  attributes: AttributeValueDto[];
  noStockBehaviour: NoStockBehaviourType;
  leadTime: string | null;
  popularity: number;
  price?: PriceDto | null;
  availability?: TyreAvailability[];
};

export type ProductDto = {
  id: string;
  variantId?: string;
  name: string;
  description: string;
  attributes: AttributeValueDto[];
  images: string[];
  keywords: string[];
  dealerSupportBrand: string | null;
  type: {
    id: string;
    name: string;
    category: {
      id: string;
      name: string;
      productGroup: string | null;
      store: StoreId;
    };
  };
  variants: ProductVariantDto[];
};

export type NumberAttributeValueDto = {
  attribute: {
    id: string;
    name: string;
  };
  type: "number";
  value: number | [number, number];
  tolerance: number | null;
  units: string | null;
};

export type TextAttributeValueDto = {
  attribute: {
    id: string;
    name: string;
    isMultiline: boolean;
  };
  type: "text";
  value: string;
};

export type SelectAttributeValueDto = {
  attribute: {
    id: string;
    name: string;
  };
  type: "select";
  value: {
    id: string;
    name: string;
  }[];
};

export type PackSizeAttributeValueDto = {
  attribute: {
    id: string;
    name: string;
  };
  type: "packsize";
  value: number;
  packName: string;
};

export type AttributeValueDto =
  | NumberAttributeValueDto
  | TextAttributeValueDto
  | SelectAttributeValueDto
  | PackSizeAttributeValueDto;

export type ProductAttributeType = "number" | "text" | "select" | "packsize";

export type NumberAttributeDto = {
  id: string;
  name: string;
  isRange: boolean;
  hasTolerance: boolean;
  units: string;
  type: "number";
};

export type TextAttributeDto = {
  id: string;
  name: string;
  maxLength: number;
  isMultiline: boolean;
  type: "text";
};

export type PackSizeAttributeDto = {
  id: string;
  name: string;
  type: "packsize";
};

export type SelectAttributeDto = {
  id: string;
  name: string;
  allowMultiple: boolean;
  options: {
    id: string;
    name: string;
  }[];
  type: "select";
};

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

export type PackSizeAttributeSpec = MakeOptional<PackSizeAttributeDto, "id">;
export type NumberAttributeSpec = MakeOptional<NumberAttributeDto, "id">;
export type TextAttributeSpec = MakeOptional<TextAttributeDto, "id">;
export type SelectAttributeSpec = MakeOptional<SelectAttributeDto, "id"> & {
  options: { id?: string; name: string }[];
};

export type ProductAttributeSpec =
  | NumberAttributeSpec
  | TextAttributeSpec
  | SelectAttributeSpec
  | PackSizeAttributeSpec;

export type ProductAttributeDto =
  | NumberAttributeDto
  | TextAttributeDto
  | SelectAttributeDto
  | PackSizeAttributeDto;

export type ProductTypeDto = {
  id: string;
  name: string;
  category: string | null;
  attributes: ProductAttributeDto[];
};

export type ProductTypeSpec = {
  id?: string;
  name: string;
  category: string | null;
  attributes: ProductAttributeSpec[];
};

export type ProductCategoryDto = {
  id: string;
  name: string;
  image: string | null;
  isActive: boolean;
  productGroup: string | null;
  store: StoreId;
  brandImages: Record<string, string>;
  brands: string[];
  access: {
    adminGroupId: number;
    accessType: "view" | "buy";
  }[];
};

export type ProductCategorySpec = MakeOptional<ProductCategoryDto, "id">;

export type MarkupBandSpec = {
  id?: string;
  startCost: number;
  endCost: number | null;
  type: "percent" | "value";
  amount: number;
};

export type SellOutGroupSpec = {
  id?: string;
  name: string;
  brand: string | null;
  brandTier: number | null;
  isCommercial: boolean | null;
  isRunflat: boolean | null;
  seasonality: string | null;
  section: string | null;
  profile: string | null;
  rim: string | null;
  lowerSpeed: string | null;
  upperSpeed: string | null;
  bands: MarkupBandSpec[];
};

export type SellOutListSpec = {
  id?: string;
  name: string;
  accountCode: string;
  applyVat: boolean;
  roundingDirection: "up" | "down";
  roundingType: "pence" | "multiple" | "none";
  roundingValue: number;
  globalMarkupType: "percent" | "value";
  globalMarkupAmount: number;
  groups: SellOutGroupSpec[];
};

export type MarkupBandDto = {
  id: string;
  startCost: number;
  endCost: number | null;
  type: "percent" | "value";
  amount: number;
};

export type SellOutGroupDto = {
  id: string;
  name: string;
  brand: string | null;
  brandTier: number | null;
  isCommercial: boolean | null;
  isRunflat: boolean | null;
  seasonality: "W" | "S" | "A" | null;
  section: string | null;
  profile: string | null;
  rim: string | null;
  lowerSpeed: string | null;
  upperSpeed: string | null;
  bands: MarkupBandDto[];
};

export type SellOutListDto = {
  id: string;
  name: string;
  accountCode: string;
  applyVat: boolean;
  roundingDirection: "up" | "down";
  roundingType: "pence" | "multiple" | "none";
  roundingValue: number;
  groups: SellOutGroupDto[];
  globalMarkupType: "percent" | "value";
  globalMarkupAmount: number;
};

export type CurrencyConversionDto = {
  id?: string;
  from: CurrencyType;
  to: CurrencyType;
  factor: number;
};

export type CurrencyType = "gbp" | "rp" | "dsp" | "bdf" | "cc";

export type CurrencyBalance = {
  accountCode: string;
  type: CurrencyType;
  value: number;
  correctAsOf: number;
  totalSpend: number;
  totalEarn: number;
};

export type LedgerAdjustmentItem = {
  accountCode: string;
  amount: number;
  date: number;
  type: CurrencyType;
  notes: string | null;
};

export type AccountLedgerItem = {
  spent: number;
  earned: number;
  amount: number;
  date: number;
  type: CurrencyType;
  isRetainedBalance: boolean;
  invoice: string | null;
  notes: string | null;
  isExpiry: boolean;
};

export type LedgerReportItem = {
  accountCode: string;
  currencies: {
    earned: number;
    spent: number;
    expired: number;
    balance: number;
    type: CurrencyType;
  }[];
};

export type MonthlyLedgerReportItem = {
  monthYear: number;
  currencies: {
    earned: number;
    spent: number;
    expired: number;
    balance: number;
    type: CurrencyType;
  }[];
};

export type CancelLineResolutionSpecDto = {
  type: "cancel";
  notes: string;
  customerInformed: boolean;
};

export type AlternativeProductResolutionSpecDto = {
  type: "alternative";
  notes: string;
  customerInformed: boolean;
  linkedAdviceNote: string;
  newStockCode: string;
};

export type OtherResolutionSpecDto = {
  type: "other";
  notes: string;
  linkedAdviceNote: string | null;
};

export type StockBecameAvailableResolutionSpecDto = {
  type: "became_avail";
  notes: string;
  linkedAdviceNote: string;
};

export type HonourPriceResolutionSpecDto = {
  type: "honour_price";
  linkedAdviceNote: string;
};

export type DifferentQtyResolutionSpecDto = {
  type: "different_qty";
  notes: string;
  customerInformed: boolean;
  linkedAdviceNotes: string[];
};

export type SellAtCurrentPriceResolutionSpecDto = {
  type: "sell_at_current_price";
  notes: string;
  customerInformed: boolean;
  linkedAdviceNote: string;
  contactInformed: number | null;
};

export type CallReportQuery = {
  administratorId?: number;
  accountCodes?: string[];
  dates?: [number, number];
  search?: string;
  problem?: number;
  skip?: number;
  take?: number;
  onlyMine?: string;
};

export type ClientUser = {
  id: number;
  type: "staff";
  name: string;
  email: string;
  roles: UserRole[];
};

export type AccountOwnershipType = "bdm" | "user" | "cc" | "report";

export type AccountOwnershipDto = {
  accountCode: string;
  name: string;
  association: AccountOwnershipType;
};

export type InvoiceLineResolutionSpecDto =
  | StockBecameAvailableResolutionSpecDto
  | SellAtCurrentPriceResolutionSpecDto
  | HonourPriceResolutionSpecDto
  | CancelLineResolutionSpecDto
  | AlternativeProductResolutionSpecDto
  | DifferentQtyResolutionSpecDto
  | OtherResolutionSpecDto;

export type InvoiceLineResolutionDto = InvoiceLineResolutionSpecDto & {
  dateAdded: number;
  addedBy: number;
};

export type DeliveryInfoDtoCommon = {
  runNumbers: string[];
  depots: number[];
  defaultDepot: DepotDto;
  assignedDepots: DepotDto[];
  collectionDepots: DepotDto[];
  runs: { day: number; cutoff: string; depot: number; nextInstance: number }[];
  expectedArrivals: { cutoff: string; range: [string, string] }[];
};

export type DeliveryInfoDtoNoLocal = DeliveryInfoDtoCommon & {
  noLocalData: true;
};

export type DeliveryInfoDtoWithLocal = DeliveryInfoDtoCommon & {
  noLocalData: false;
  casingCollectionInstruction: number;
  otherCasingCollectionInstruction: string | null;
  isPriority: boolean;
  exemptFromSecurityCheck: boolean;
  deliveryInstructions: string;
  salesManagerComments: string;
  lastEditedBy: IdentityDisplay | null;
  lastEditedOn: number | null;
};

export type ValidatePinSpec = {
  pin: string;
  type: "delivery" | "collection";
};

export type UpdateAccountSecuritySpec = {
  deliveryPin?: string | null;
  collectionPin?: string | null;
};

export type DeliveryInfoSpec = {
  casingCollectionInstruction: number;
  otherCasingCollectionInstruction: string | null;
  isPriority: boolean;
  exemptFromSecurityCheck: boolean;
  deliveryInstructions: string;
  salesManagerComments: string;
  collectionDepots: string[];
};

export type DeliveryInfoDto = DeliveryInfoDtoNoLocal | DeliveryInfoDtoWithLocal;

export type AdviceNoteDto = {
  adviceNoteNumber: string;
  accountCode: string;
  date: number;
  fitter: string;
  lines: AdviceNoteLineDto[];
};

export type AdviceNoteLineDto = {
  stockCode: string;
  quantity: number;
  linePrice: number;
  returns: {
    id: string;
    ref: string;
    qty: number;
  }[];
};

export type CamInvoiceDto = {
  invoiceNumber: string;
  accountCode: string;
  date: number;
  fitter: string;
  lines: CamInvoiceLineDto[];
};

export type CamInvoiceLineDto = {
  stockCode: string;
  quantity: number;
  linePrice: number;
};

export type OrderDto = {
  id: string;
  customerCode: string;
  customerOrderNumber: string;
  regNumber: string | null;
  orderPlaced: number;
  orderSource: string;
  fitter: string | null;
  invoices: InvoiceDto[];
};

export type InvoiceDto = {
  id: string;
  depot: number;
  midasOrderId: number | null;
  customerCode: string;
  invoiceNumber: string | null;
  adviceNote: string | null;
  midasCheckCount: number;
  midasLastChecked: number | null;
  lines: InvoiceLineDto[];
  vanRunId: string | null;
  isCollection: boolean;
};

export type InvoiceLineDto = {
  id: string;
  stockCode: string;
  quantity: number;
  linePrice: number;
  currency: CurrencyType;
  midasResponse: string | null;
  resolution: InvoiceLineResolutionDto;
};

export type LineResolutionDto = {};

export type UserGroupSummaryDto = {
  userGroupID: number;
  name: string;
};

export type BasketOrderDto = {
  accountCode: string;
  customerOrderNumber: string;
  regNumber: string;
  collectingVehicleReg?: string;
  pin?: string;
  source: "chase" | "portal" | "api";
  lines: { stockCode: string; quantity: number; supply?: string }[];
  addressId?: number | null;
};

export type AccountBenefitDto = {
  accountBenefitID: number;
  benefitCode: string;
  title: string;
  description: string;
};

export type PartnershipDetailsDto = {
  accountId: number;
  accountCode: string;
  hasAccess: boolean;
  rewardPoints: number;
  dealerSupportBrand: number;
  dealerSupportPoint: boolean;
  rewardPointsEnabled: boolean;
  businessDevelopmentFund: boolean;
  dsp: number;
  bdf: number;
  consumableCredit: number;
  dealerSupportOverdraft: number;

  userGroups: UserGroupSummaryDto[];
  accountBenefits: AccountBenefitDto[];
  orderSizeMinimums: OrderMinimumDto[];
};

export type OrderMinimumDto = {
  orderSizeMinimumID: number;
  currencyType: number;
  size: number;
};

export type CustomerRewardTemplateDto = {
  name: string;
  pointsPerSpend: number;
  spendIncrement: number;
  rules: {
    points: number;
    bdf: number;
    dsp: number;
    aggregation: "all" | "any";
    name: string;
    conditions: {
      valueOne: string;
      valueTwo?: string;
      operator: string;
      type: string;
    }[];
  }[];
};

export type UserRole =
  | "Admin"
  | "CRM"
  | "CreditControl"
  | "SalesManager"
  | "CreditControlManager"
  | "OperationsManager"
  | "ManagementAccountant"
  | "OrderResolutions";

export type StockMetaSpec = {
  bsw: boolean | null;
  owl: boolean | null;
  rwl: boolean | null;
  wsw: boolean | null;
  rbl: boolean | null;
  ww: boolean | null;
  vintage: boolean | null;
  por: boolean | null;
  tubeType: string;
  msMark?: boolean | null;
  showInBrochureSite?: boolean;

  oe_1: string | null;
  oe_2?: string | null;
  oe_3?: string | null;
  oe_4?: string | null;
  oeModels?: string | null;

  runFlatTech: string | null;

  rimProtector: boolean | null;
  rimProtectorTech: string | null;

  noiseCancelling: boolean | null;
  noiseCancellingTech: string | null;

  selfSealing: boolean | null;
  selfSealingTech: string | null;

  ev: boolean | null;
  evTech: string | null;

  sizePrefix: string | null;
  commercialSuffix: string | null;
  patternSuffix: string | null;
  euDirectiveNumber: string;
  webLink: string;
};

export type PricatEntry = {
  id: number;
  ean: string;
  description: string;
  manufacturer: string;
  supplierCode: string;
  weight: number;
  section: number;
  profile: number;
  rim: number;
  speed: string;
  construction: string;
  ply: null | number;
  pattern: string;
  loadIndex: string;
  runFlat: number;
  runFlatTech: string;
  exload: number;
  remould: number;
  season: string;
  threepeaks: number;
  spsaver: number;
  class: string;
  fuelClass: string;
  wetGripClass: string;
  noiseDb: number;
  noiseClass: number;
  rimProtector: number;
  rimProtectorTech: string;
  bsw: number;
  owl: number;
  rwl: number;
  wsw: number;
  rbl: number;
  ww: number;
  tubeType: string;
  msMark?: any;
  oe_1: string | null;
  oe_2?: string | null;
  oe_3?: string | null;
  oe_4?: string | null;
  oeModels?: string | null;
  noiseCancelling: number | null;
  noiseCancellingTech: string | null;
  selfSealing: number | null;
  selfSealingTech: string | null;
  vintage: number;
  por: number;
  sizePrefix: string | null;
  commercialSuffix: string | null;
  euDirectiveNumber: string;
  webLink: string;
  lastSeenPricat: Date;
  pricatImporter: string;
  markedObsolete: Date | null;
  asymm: number | null;
  direct: number | null;
  ev: number | null;
  evTech: string | null;
  popularity: number;
};

export type Supplier = {
  id: number;
  name: string;
  crossReference: string;
  useForStock: boolean;
  cutoff: number;
  duration: number;
};

export type NoDataSupplier = {
  crossReference: string;
};

export type TyreQuickSearch = {
  description: string;
  ean: string;
  importer: string | null;
  stockCode?: string;
  manufacturer?: string;
};

export type CreditControlOverviewItem = {
  accountID: number;
  accountCode: string;
  camStatus: number;
  statusReason: number;
  creditOp: string;
  dateLastPurchase: Date | null;
  lastCallReport: Date | null;
  openTaskCount: number;
  currentBalance: number;
  balance30Day: number;
  balance60Day: number;
  balance90Day: number;
  balance120Day: number;
  extraPay: number;
  payTerms: number;
  ccTaskDeadline: Date | null;
  overdue: number;
  keyIndependent: boolean;
  imprestQty: number;
};

export type VehicleBrand = {
  id?: number;
  name: string;
  vrmReference: string | null;
  logo: string | null;
};

export type VehicleBrandDto = VehicleBrand;

export interface DVLA {
  VrmHisID: number;
  Cusid: number;
  DvlaID: string;
  Make: string;
  Model: string;
  DateOfTransaction: string;
  VinConfirmationFlag: boolean;
  EngineCapacity: string;
  DoorPlan: string;
  DateFirstRegistered: string;
  YearOfManufacture: string;
  Scrapped: boolean;
  Exported: boolean;
  Imported: boolean;
  Colour: string;
  Transmission: string;
  EngineNumber: string;
  VinSerialNumber: string;
  DoorPlanLiteral: string;
  MVRISMakeCode: string;
  MVRISModelCode: string;
  DTPMakeCode: string;
  DTPModelCode: string;
  TransmissionCode: string;
  Gears: string;
  Fuel: string;
  CO2Emissions: string;
  UsedBeforeFirstReg: boolean;
  ImportNonEu: boolean;
  DateLookedUp: Date;
  VRM: string;
  ExportedDesc: string;
  ImportedDesc: string;
  ImportNonEUDesc: string;
  ScrappedDesc: string;
  VinConfirmationFlagDesc: string;
  DVLAAcessLevel: number;
  MOTDue: string;
  TaxDue: string;
  VehicleStatus: string;
}

export interface VehicleRecord {
  ChassisID: string;
  Modelid: string;
  ManufacturerID: string;
  ModelName: string;
  KW: string;
  VIN: string;
  UKYear: string;
  EuroVin: string;
  SortOn: string;
  TyreSize: string;
  LoadIndex: string;
  SpeedIndex: string;
  TyrePressure: string;
  TyreSizeR: string;
  LoadIndexR: string;
  SpeedIndexR: string;
  TyrePressureR: string;
  RimSize: string;
  RimOffset: string;
  RimSizeR: string;
  OffSetR: string;
  PCD: string;
  CentreBore: string;
  NutBoltThreadType: string;
  NutBoltHex: string;
  BoltLength: string;
  NutBoltTorque: string;
  MaxETFront: string;
  MinETFront: string;
  MaxETRear: string;
  MinETRear: string;
  MaxWheelLoad: string;
  FrontVehicleTrack: string;
  RearVehicleTrack: string;
  MaxRimWidthFront: string;
  MaxRimWidthRear: string;
  LadenTPF: string;
  LadenTPRear: string;
  GVW: string;
  Max_Speed: string;
  Front_Axle_Weight: string;
  Rear_Axle_Weight: string;
  Kerb_weight: string;
  Caliper: string;
  CentreBoreR: string;
  ManuFacturerName: string;
  RunFlatF: string;
  RunFlatR: string;
  ExtraLoadF: string;
  ExtraLoadR: string;
}

export interface VehicleOption {
  Option: string;
  FrontTyreSize: string;
  RearTyreSize: string;
  FrontLoadIndex: string;
  RearLoadIndex: string;
  FrontSpeedIndex: string;
  RearSpeedIndex: string;
  FrontTyrePressure: string;
  RearTyrePressure: string;
  FrontLadenPressure: string;
  RearLadenPressure: string;
  FrontRimSize: string;
  RearRimSize: string;
  FrontRimOffset: string;
  RearRimOffset: string;
  RunFlatF: string;
  RunFlatR: string;
  ExtraLoadF: string;
  ExtraLoadR: string;
}

export type UpstepType = "FrontUpstep" | "RearUpstep" | "Upstep";

export interface VehicleUpstep {
  UpstepID: string;
  ChassisID: string;
  UpstepType: UpstepType;
  UpstepCode: string;
  WheelSize: string;
  Tyre1: string;
  Tyre2: string;
  Tyre3: string;
  Tyre4: string;
  Tyre5: string;
  Tyre6: string;
  Tyre7: string;
  Tyre8: string;
  MinOffSet: string;
  MaxOffSet: string;
}

export interface DriveRight {
  VehicleRecords: VehicleRecord[];
  VehicleUpsteps: VehicleUpstep[][];
  VehicleOptions: VehicleOption[][];
}

export interface VrmData {
  DVLA: DVLA;
  DriveRight: DriveRight;
}

export type RoleVisibility =
  | "CreditControl"
  | "SalesManager"
  | "AccountManager"
  | "BDM";

export type AccountFile = {
  id: number;
  administratorId: number;
  typeName: string;
  typeId: number | null;
  uploadDate: Date;
  roleVisibility: RoleVisibility[];
  filename: string;
  originalFilename: string;
};

export type AccountFileSpec = {
  typeId: number | null;
  customTypeName: string | null;
  filename: string;
  originalFilename: string;
  roleVisibility: RoleVisibility[];
};

export type AccountFileType = {
  id: number;
  name: string;
};

export enum TypeOfCall {
  RoutineVisit,
  NewProspect,
  RespondingToIssue,
  InstallingGroupLink,
  RespondingToGroupLinkIssue,
  ImprestStockCheck,
  InvestigatingLowSpend,
  SocialMediaDiscussion,
  BDFJobDiscussion,
  BDFJobInstall,
  NewBrandDealerPitch,
  CreditControlCall,
}

export enum CallMethod {
  Unknown,
  InPerson,
  ByPhone,
  Email,
}

export enum CallProblem {
  None,
  Minor,
  Major,
}

export enum MarketPerceptionRating {
  Unknown,
  Weak,
  Normal,
  Strong,
}

export type CallReportSpec = {
  method: CallMethod;
  callType: TypeOfCall;
  report: string;
  problem: CallProblem;
  problemDescription: string | null;
  callTime: number;
  callEndTime: number;
  customCallReportAttendeeName: string | null;
  userID: number | null;
  callReportAttendeeID: number | null;
  accountID: number;
  marketPerception: MarketPerceptionRating;
};

export type CallReport = {
  /**
   * @deprecated
   */
  callReportID: number;
  id: number;
  callTime: number;
  callEndTime: number | null;
  dateCreated: number;
  dateEdited: number | null;
  callType: number;
  method: number;
  report: string;
  problem: number;
  isLegacyImport: boolean;
  marketPerception: number;
  problemDescription: string | null;
  account: null | {
    /**
     * @deprecated
     */
    accountID: number;
    id: number;
    accountCode: string;
    name: string;
  };
  prospect: null | {
    /**
     * @deprecated
     */
    prospectId: number;
    id: number;
    name: string;
  };
  createdBy: AdministratorSummary;
  attendee: CallReportAttendeeSummary | null;
  associatedTasks: { id: number; title: string }[];
};

export type CallReportAttendeeSummary = {
  callReportAttendeeID?: number;
  name: string;
};

export type CallReportDto = CallReport;

export type AccountDisplay = {
  id: number;
  code: string;
  name: string;
};

export type ChaseTaskDeadlineDto = {
  chaseTaskDeadlineID: number;
  deadline: number;
  dateSet: number;
  administrator: AdministratorSummary;
};

export interface ChaseTaskAssignmentDto {
  chaseTaskAssignmentID: number;
  dateAssigned: number;
  dateRemoved: number | null;
  administrator: AdministratorSummary | null;
  group: AdministratorGroupSummary | null;
  assignedBy: AdministratorSummary;
  removedBy: AdministratorSummary | null;
}

export interface ChaseTaskStatusChangeDto {
  chaseTaskStatusChangeID: number;
  dateStamp: number;
  type: TaskStatus;
  administrator: AdministratorSummary;
}

export interface ChaseTaskCommentDto {
  chaseTaskCommentID: number;
  text: string;
  postTime: number;
  creator: AdministratorSummary;
}

export interface ChaseTaskCallReportAttachmentDto {
  chaseTaskCallReportAttachmentID: number;
  postTime: number;
  creator: AdministratorSummary;
  callReport: CallReport;
}

export type CampaignOutcomeDto = {
  campaignOutcomeID: number;
  text: string;
  isSuccess: boolean;
  orderIndex: number;
};

export type CallReportOutcomeChangeDto = {
  chaseTaskOutcomeChangeID: number;
  dateStamp: number;
  administrator: AdministratorSummary;
  outcome: CampaignOutcomeDto;
};

export interface NonStockRequestSummary {
  nonStockRequestID: number;
  optionsAdded: number | null;
  rejected: number | null;
  cancellationReason: string | null;
  optionSelected: number | null;
  optionsAddedBy: AdministratorSummary | null;
}

export interface CampaignDto {
  campaignId?: number;
  title: string;
  description: string;
  requiresCallReportToClose: boolean;
  assignmentMode: number;
  creator: AdministratorSummary;
  dateCreated: number;

  outcomes: CampaignOutcomeDto[];
}

export interface ChaseTaskDto {
  id: number;
  chaseTaskID: number;
  text: string;
  title: string;
  creationTime: number;
  requiresCallReportToClose: boolean;
  account: AccountDisplay;
  creator: AdministratorSummary;
  assignments: ChaseTaskAssignmentDto[];
  comments: ChaseTaskCommentDto[];
  statusChanges: ChaseTaskStatusChangeDto[];
  deadlines: ChaseTaskDeadlineDto[];
  callReportAttachments: ChaseTaskCallReportAttachmentDto[];
  outcomeChanges: CallReportOutcomeChangeDto[];
  subscribed: boolean;
  nonStockRequest: NonStockRequestSummary | null;
  campaign: CampaignDto | null;
}

export type TaskSpec = {
  text: string;
  assignedAdminIds: number[];
  assignedAdminGroupIds: number[];
  deadline: number;
  callReportId: number | null;
  accountId?: number;
  accountCode?: string;
  title: string;
  requiresCallReportToClose: boolean;
  subscribe: boolean;
};

export type TaskSummary = {
  chaseTaskID: number;
  text: string;
  title: string;
  creationTime: Date;
  subscribed: boolean;
  unread: boolean;
  requiresCallReportToClose: boolean;
  currentStatus: number;
  currentDeadline: Date;
  lastViewed: Date | null;
  lastComment: Date | null;
  lastCommenter: number | null;
  commentCount: number;
  assignedToYou: boolean;
  creator: { administratorID: number; name: string; email: string };
  account: { accountID: number; accountCode: string };
  nonStockRequest: null | {
    id: number;
    optionsAdded: Date | null;
    ordered: Date | null;
    invoiced: Date | null;
    rejected: Date | null;
    optionSelected: Date | null;
  };
};

export enum TaskStatus {
  Open,
  InProgress,
  Blocked,
  Closed,
}

export type TaskUpdate = {
  comment?: string | null;
  status?: TaskStatus | null;
  assignedAdminIds?: number[] | null;
  assignedAdminGroupIds?: number[] | null;
  callReportId?: number | null;
  campaignOutcomeId?: number | null;
  deadline?: Date | null;
};

export type DeliveryBasic = {
  id: number;
  vanRef: number;
  vanReg: string;
  driver: { id: number; name: string; email: string };
};

export type DeliveryFull = DeliveryBasic & {
  code: string;
  signatureName: string;
  comment: string;
  completedType: number;
  paymentCash: number;
  paymentCheque: number;
  paymentCard: number;
  paymentExpected: number;
  signatureTime: Date;
  signatureImageId: number;
  lines: {
    stockCode: string;
    description: string;
    expectedQty: number;
    confirmedQty: number;
  }[];
};

export type DriverReport = {
  id: number;
  name: string;
  deliveries: {
    id: number;
    accountCode: string;
    photoId: number | null;
    timestamp: Date | null;
    ratingCount: number;
    averageRating: number | null;
  }[];
};

export type RatingsByDriverReport = {
  driverId: number;
  name: string;
  deliveryCount: number;
  noPodCount: number;
  ratingCount: number;
  adequateRatings: number;
  averageScore: number | null;
  deliveries: {
    accountCode: string;
    date: Date | null;
    photoId: number | null;
  }[];
};

export type PodRatingByAdminReportItem = {
  administratorId: number;
  name: string;
  ratingCount: number;
  adequateRatings: number;
  byReason: number[];
};

export type PodReportByPeriodItem = {
  period: number;
  ratingCount: number;
  adequateRatings: number;
  uniqueImagesRated: number;
  withoutPod: number;
};

export type Fitment = {
  id: number;
  section: string;
  profile: string;
  rim: string;
  isInvalid: boolean;
  aliases: string[];
  popularity: number;
  standardLoadIndex: number | null;
  reinforcedLoadIndex: number | null;
  highLoadIndex: number | null;
  commercialLoadIndex1: string | null;
  commercialLoadIndex2: string | null;
  commercialLoadIndex3: string | null;
  standardRimWidth: number | null;
  approvedRims: string[];
  designSectionWidth: number | null;
  designOverallDiameter: number | null;
  maxIsOverallWidth: number | null;
  maxIsOverallDiameter: number | null;
  innerTubeCode1: string | null;
  innerTubeCode2: string | null;
};

export type TyreQueryDto = {
  section?: string;
  profile?: string;
  rim?: string;
  speed?: string;
  load?: string;
  brand?: string;
  pattern?: string;
  showObsolete?: boolean;
  hideNoStock?: boolean;
  season?: string;
  runflat?: boolean;
  commercial?: boolean;
  brandTier?: number;
  stockCodes?: string[];
  stockCode?: string;
  ean?: string;
  vehicle?: number;
  accountCode?: string;
  brochure?: boolean;
};

export type FitmentDto = Fitment;

export type TyrePatternSummaryDto = {
  id: number;
  name: string;
  crossReference: string;
  manufacturerName: string;
  logo: string | null;
  imageCount: number;
  manufacturer: { id: number; name: string } | null;
};

export type TyrePatternDto = {
  id: number;
  crossReference: string;
  name: string;
  manufacturer: { id: number; name: string; icon: string | null };
  allTerrain: boolean | null;
  mudTerrain: boolean | null;
  highwayTerrain: boolean | null;
  track: boolean | null;
  directional: boolean | null;
  asymmetrical: boolean | null;
  threepeaks: boolean | null;
  trailer: boolean | null;
  car: boolean | null;
  description: string | null;
  lightCommercial: boolean | null;
  logo: string | null;
  uhp: boolean | null;
  por: boolean | null;
  vintage: boolean | null;
  suv: boolean | null;
  premiumTouring: boolean | null;
  studded: boolean | null;
  studdable: boolean | null;
  nordicWinter: boolean | null;
  iceLabel: boolean | null;
  season: "S" | "W" | "A" | null;
  msMark: boolean | null;
  allowAdg: boolean;
  images: string[];
  specialtyTreadStyles: SpecialtyTreadStyleType[];
};

export type VehicleManufacturer = {
  id: number;
  name: string;
};

export type VehicleManufacturerDto = VehicleManufacturer;

export type MidasProxyRequest = {
  method: "GET" | "POST";
  midasId: string;
  url: string;
  params: Record<string, any>;
};

export type DepotDto = {
  id: string;
  depotNumber: number;
  name: string;
  address: PhysicalAddressDto | null;
  midasSiteId: number | null;
  collectionVanRun: number | null;
  openingTimes: {
    day: number;
    open: number;
    close: number;
  }[];
};

export type DepotTransferDto = {
  id: string;
  cutoff: number;
  origin: {
    id: string;
    name: string;
    number: number;
  };
  target: {
    id: string;
    name: string;
    number: number;
  };
  day: number;
  duration: number;
  nextInstance?: number;
};

export type AdministratorSummary = {
  administratorId: number;
  name: string;
  email: string;
};

export type AdministratorGroup = {
  administratorGroupID: number;
  receiveAccountCreationTasks: boolean;
  receiveAccountSetUpTasks: boolean;
  receiveNonStockTasks: boolean;
  name: string;
};

export type AdministratorGroupSummary = {
  id: number;
  name: string;
};

export type AdministratorGroupDto = AdministratorGroup;

export type Administrator = {
  administratorID: number;
  name: string;
  email: string;
  isActive: boolean;
  roles: number;
  camUserCrossRef: string | null;
  camRepCrossRef: string | null;
  camCreditOpCrossRef: string | null;
  managerID: number | null;
  groups: AdministratorGroup[];
  directReports: AdministratorSummary[];
};

export type AdministratorDto = Administrator;

export type SalesOverviewItem = {
  accountID: number | null;
  accountCode: string;
  postCode: string;
  camStatus: CamStatuses;
  statusReason: StopReasons;
  dateLastPurchase: Date;
  imprestQuantity: number;
  thirtyDayVolume: number;
  nonTyreValue: number;
  lastCallReport: Date | null;
  openTaskCount: number;
  contactQuality: number | null;
};

export type ProspectOverviewItem = {
  accountCode: string;
  prospectStatus: ProspectStatus;
  name: string;
  createdOn: Date;
  postCode: string;
  callReportCount: number;
  lastCallReport: Date | null;
};
