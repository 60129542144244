import { DataSheetType, DataSheetTypeNames } from "@oaktyres/model";
import {
  getDownloadUrlForDataSheet,
  useAccountNew,
  useApplicableDataSheets,
  useDeliveryInfo,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Loader,
  Panel,
  Text,
} from "@oaktyres/ui";
import { groupBy } from "lodash";
import React from "react";
import { useState } from "react";
import {
  FaChevronDown,
  FaChevronRight,
  FaDownload,
  FaInfo,
  FaQuestionCircle,
} from "react-icons/fa";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

export const DataSheetPane = () => {
  const [scopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);
  const deliveryInfo = useDeliveryInfo(scopedAccount);
  const sheets = useApplicableDataSheets(scopedAccount);
  const [hidden, setHidden] = useState<DataSheetType[]>([]);

  if (account.data == null || deliveryInfo.data == null) {
    return (
      <Panel width="100%" p={5}>
        <Loader />
      </Panel>
    );
  }

  const download = async (id: string) => {
    const url = await getDownloadUrlForDataSheet(id);

    window.location.assign(url);
  };

  const groupedSheets = groupBy(sheets.data ?? [], (x) => x.type);

  const pluralize = (input: string) =>
    input.endsWith("s") ? input : input + "s";

  const toggle = (type: DataSheetType) => {
    if (hidden.includes(type)) {
      setHidden(hidden.filter((x) => x !== type));
    } else {
      setHidden([...hidden, type]);
    }
  };

  return (
    <Panel width="100%" p={1}>
      {sheets.isLoading ? (
        <Loader />
      ) : sheets.data?.length === 0 ? (
        <Flex
          p={5}
          alignItems={"center"}
          flexDirection="column"
          height="100%"
          justifyContent={"center"}
        >
          <Circle icon={FaInfo} color="grey3" size="xlarge" mb={2} />
          <Text
            fontWeight={600}
            color="grey2"
            width={"500px"}
            textAlign="center"
          >
            There have been no purchases of products that require a data sheet
            on this account.
          </Text>
        </Flex>
      ) : (
        Object.entries(groupedSheets).map(([type, items]) => (
          <Box mb={2}>
            <Flex p={2}>
              <IconButton
                icon={
                  hidden.includes(type as DataSheetType)
                    ? FaChevronRight
                    : FaChevronDown
                }
                color="primary"
                mr={1}
                onClick={() => toggle(type as DataSheetType)}
              />
              <Text fontWeight={600}>
                {pluralize(DataSheetTypeNames[type as DataSheetType])}
              </Text>
            </Flex>
            {hidden.includes(type as DataSheetType)
              ? null
              : items.map((item) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent="space-between"
                    p={2}
                    borderBottom={1}
                  >
                    <Flex alignItems={"center"}>
                      <Circle icon={FaDownload} color="primary" mr={2} />
                      <div>
                        <Text lineHeight={1.2}>
                          {DataSheetTypeNames[item.type]} for:{" "}
                          <strong>{item.stockCode}</strong>
                        </Text>
                        <Text fontWeight={600} fontSize={1} color="grey2">
                          {item.productName}
                        </Text>
                      </div>
                    </Flex>
                    <Button
                      icon={FaDownload}
                      onClick={() => download(item.id)}
                      justifySelf="flex-end"
                    >
                      Download
                    </Button>
                  </Flex>
                ))}
          </Box>
        ))
      )}
    </Panel>
  );
};
