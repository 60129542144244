export type TyreConstructionCode = "R" | "-";

export type SpecialtyFitmentDto = {
  id: string;
  construction: TyreConstructionCode;
  tyreHeight: number | null;
  tyreWidth: number | null;
  rimWidth: number | null;
  rimDiameter: number | null;
  section: number | null;
  profile: number | null;
  prefix: string | null;
  suffix: string | null;

  innerTubes: string[];
  aliases: string[];
};

export type SpecialtyFitmentSpec = Omit<SpecialtyFitmentDto, "id"> & {
  id?: string;
};

export const formatSpecialtyFitment = (
  fitment: SpecialtyFitmentDto | SpecialtyFitmentSpec,
) => {
  let result = "";

  result += fitment.prefix ? fitment.prefix : "";
  result += [fitment.section, fitment.profile].filter((x) => x).join("/");
  result += [fitment.tyreHeight, fitment.tyreWidth?.toFixed(2)]
    .filter((x) => x)
    .join("x");

  result += fitment.rimWidth ? `/${fitment.rimWidth.toFixed(2)}` : "";
  result += [fitment.construction, fitment.rimDiameter, fitment.suffix]
    .filter((x) => x)
    .join("");

  return result;
};
