import {
  formatSpecialtyFitment,
  PriceDto,
  ProductDto,
  SpecialtyTyreDto,
} from "@oaktyres/model";
import { formatCurrency, Text, Flex, Panel } from "@oaktyres/ui";
import { minBy } from "lodash";
import React from "react";
import { FaLayerGroup } from "react-icons/fa";
import styled from "styled-components";

export type SpecialtyTyreCardProps = {
  product: SpecialtyTyreDto;
  onClick?: React.MouseEventHandler;
};

const defaultProductImage = "https://via.placeholder.com/640x640";

const Container = styled(Panel)`
  border: 0;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.02, 1.02, 1);
    box-shadow: ${(props) => props.theme.shadows[2]};
  }
`;

const ProductImage = styled.img`
  display: block;
  object-fit: contain;
  width: 100%;
  height: 200px;
`;

function getImage(product: SpecialtyTyreDto) {
  return product.pattern.images.length > 0
    ? `${process.env.REACT_APP_STATIC_ROOT}${product.pattern.images[0]}`
    : defaultProductImage;
}

const ProductPrice = ({ product }: { product: SpecialtyTyreDto }) => {
  let text =
    product.price?.value == null
      ? ""
      : formatCurrency("gbp")(product.price.value);

  return (
    <Text fontWeight={600} fontSize="0.8em">
      {text}
    </Text>
  );
};

export const SpecialtyTyreCard = ({
  product,
  onClick,
}: SpecialtyTyreCardProps) => {
  return (
    <Container overflow={"hidden"} onClick={onClick}>
      <Flex flexDirection={"column"}>
        <ProductImage src={getImage(product)} />
        <Flex p={2} alignItems="flex-end" justifyContent="space-between">
          <Flex flexDirection="column" alignItems="">
            <Text fontWeight={600} fontSize="1em">
              {product.pattern.name}
            </Text>
            <Flex alignItems="center" mb="2px">
              <FaLayerGroup fontSize="0.8em" color="#ddd" />
              <Text color="grey3" fontWeight={600} fontSize="0.8em" ml={1}>
                {product.fitment == null
                  ? ""
                  : formatSpecialtyFitment(product.fitment)}
              </Text>
            </Flex>
            <ProductPrice product={product} />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
